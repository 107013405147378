import cx from 'classnames';
import { toUrl } from 'gatsby-source-gravatar';
import React, { memo, useMemo, useState, useContext } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useUser from '../../hooks/useUser';
import styles from './Avatar.module.css';
import { handleKeyUp } from '../../utils';
import ModalContext from '../../contexts/ModalContext';

const Avatar = ({ className }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const { emitter, events } = useContext(ModalContext);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    emitter.emit(events.LOGOUT_MODAL);
    handleClose();
  };

  const handleAccount = () => {
    emitter.emit(events.DELETE_ACCOUNT_MODAL);
    handleClose();
  };

  if (user == null) {
    return;
  }
  const photoURL = useMemo(() => toUrl(user.email || '', 'size=128&d=mp'), [
    user.email,
  ]);

  return (
    <div>
      <div
        tabIndex="0"
        role="button"
        className="flex focus:outline-none"
        onClick={handleClick}
        onKeyUp={(e) => handleKeyUp(e, handleClick)}
      >
        <img
          src={photoURL}
          alt={user.displayName || 'Anonymous User'}
          className={cx(styles.container, className)}
        />
      </div>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={handleAccount}>
          {t('shared.buttons.account')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t('shared.buttons.logout')}</MenuItem>
      </Menu>
    </div>
  );
};

export default memo(Avatar);
